<template>
  <div class="login-page">
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <div class="image">
          <img src="../assets/images/logo.png" alt="main logo" />
          <!-- <vuetify-logo /> -->
        </div>
        <h2 v-if="paid && !dataLoading" style="color: #44a04b">
          شكراً لك ، تمت عملية الدفع بنجاح
        </h2>
        <h2 v-if="!paid && !dataLoading" style="color: #930f0f">
          للأسف فشلت عملية الدفع
        </h2>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { API_URL } from "../config";

export default {
  data() {
    return {
      paid: false,
      dataLoading: true,
    };
  },

  methods: {
    async checkPaymentStatus() {
      const resource =
        `${API_URL}/payment/check/` +
        this.$route.params.payment_id +
        "?payment_id=" +
        this.$route.query.id;
      this.$http
        .get(resource, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            if (response.data.data.status == "paid") {
              this.paid = true;
            }
          }
          this.dataLoading = false;
        });
    },
  },
  created() {
    this.checkPaymentStatus();
  },
};
</script>
<style>
.login-page {
  width: 100%;
}
.backToHome {
  color: #fff !important;
  text-decoration: none;
}
.green-btn {
  background-color: #4c9e53;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.red-btn {
  background-color: red;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.login-page h2 {
  margin: 0 0 25px;
  text-align: center;
  color: #614c37;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
}
.login-page .image {
  max-width: 100px;
  margin: 50px auto;
}
.login-page .image img {
  width: 100%;
}
.tel-form {
  direction: ltr !important;
  text-align: left;
  max-width: 600px;
  margin: auto;
}
.tel-form input {
  border-radius: 7px;
  font-size: 14px;
  max-width: 100%;
  padding: 15px;
  border: none;
  outline: none;
  background-color: #fff;
}
.tel-form .btn {
  text-align: center;
  margin: 50px auto;
  width: 100%;
  font-size: 17px;
  border-radius: 7px;
  padding: 30px !important;
  font-weight: 600;
  background-color: #fed24e !important;
  border: 2px solid #fed24e;
  color: #424242 !important;
}
</style>
